import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import MenuContext from '@context/MenuContext'
import Header from './header/header'
import Footer from './footer/footer'

function Layout ({ children }) {
  useEffect(() => {})

  return (
    <MenuContext.Consumer>
      {menu => (
        <div className={`layout ${ menu.open ? 'menu-open' : '' }`}>
          <Header/>
          <main className="layout__content">
            {children}
          </main>
          <Footer />
        </div>
      )}
    </MenuContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import { Link } from 'gatsby'
import React from 'react'
import MessageIcon from '@icons/message.svg'

const Footer = () => (
  <footer className="footer">
    <nav className="container">
      <ul className="footer__nav">
        <li><Link to="/mentions-legales" activeClassName="active">Mentions légales</Link></li>
        <li><Link to="/politique-confidentialite" activeClassName="active">Politique de confidentialité</Link></li>
      </ul>
      <p className="footer__copyright">© Acolad</p>
      <Link to="/contact" className="footer__contact"><MessageIcon/> <span>Contactez-nous</span></Link>
    </nav>
  </footer>
)

export default Footer

import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Icon from '@icons/arrow-b.svg'

const Scroll = () => (
  <div className="scroll" aria-hidden="true">
    <AnchorLink href='#content' aria-label="Accéder au contenu de la page">
      <span className="scroll__text">Scroll</span>
      <Icon />
    </AnchorLink>
  </div>
)

export default Scroll

import React from 'react'
import { Location } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import Navbar from './Navbar'
import Headline from './Headline'
import Scroll from './Scroll'
import './header.scss'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      styles: {}
    }
  }

  componentDidMount () {
    const height = document.getElementById('header').clientHeight
    this.setState({ styles: { height: height + 'px' } })
  }

  render () {
    // Récupération des données de la page active pour déterminer le contenu du header :
    const activePage = this.props.pagesData.find(
      page => {
        const active = this.props.activePage.replace(/\//g, '')
        const url = page.node.path.replace(/\//g, '')
        return active === url
      })
    const page404 = this.props.pagesData[5]
    const data = activePage ? activePage.node : page404.node // Gestion des 404 (empêche erreur au build)
    const splitPath = data.path.split('/')
    let extraclass = splitPath.length > 1 ? splitPath[1] : ''
    return (
      <header id="header" style={(extraclass ? [] : this.state.styles)} className={(extraclass ? 'header header--pages header--' + extraclass : 'header header--home')}>
        <Navbar menuOpen={false} />
        <Headline data={data} />
        {data.path === '/' && <Scroll/>}
      </header>
    )
  }
}

// Envoi au header des données de toutes les pages et du path de la page active :
export default () => {
  const data = useStaticQuery(pagesQuery)
  const pagesData = [...data.pages.edges, ...data.projects.edges]
  return (
    <Location>
      {locationProps =>
        <Header
          pagesData={pagesData}
          activePage={locationProps.location.pathname}
        />
      }
    </Location>
  )
}

const pagesQuery = graphql`
  query {
    pages : allPagesJson {
      edges {
        node {
          path
          title
          subtitle
        }
      }
    }
    projects : allProjectsJson {
      edges {
        node {
          path
          title
          description
        }
      }
    }
  }
`

import React from 'react'
import './headline.scss'
import Typist from 'react-typist'
import TypistLoop from 'react-typist-loop'

const Headline = props => {
  const acolad = '< / >' // pour escape les brackets en JSX
  const activePage = props.data.path === '/' ? 'home' : 'pages'
  const subtitle = props.data.subtitle

  return (
    <div className={'headline ' + activePage}>
      <div>
        {activePage === 'home' && <p className="headline__logotext" aria-hidden="true">{acolad}</p>}
        {activePage === 'home' && <h1 className="headline__company">Acolad développement</h1>}
        {activePage === 'pages' && <h1 className={'headline__title ' + activePage}>{props.data.title}</h1>}
        {activePage === 'home' && <h2 className={'headline__title ' + activePage}>
        Nous travaillons sur<br />des projets <span className="headline__title__strong">
            <TypistLoop interval={500}>
              {[
                'agiles',
                'conformes RGPD',
                'robustes',
                'inclusifs'
              ].map(text =>
                <Typist key={text} startDelay={200} cursor={{ blink: true, hideWhenDone: false }}>
                  {text}
                  <Typist.Backspace count={text.length} delay={1500} />
                </Typist>
              )}
            </TypistLoop>
          </span>
        </h2>}
        {(activePage === 'pages' && subtitle) && <p className={'headline__subtitle ' + activePage}>{subtitle}</p>}
      </div>
    </div>
  )
}

export default Headline

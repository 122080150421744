import React from 'react'
import { Link } from 'gatsby'
import MenuContext from '@context/MenuContext'
import logo from '@images/logo.png'
import './navbar.scss'

const Navbar = props => (
  <MenuContext.Consumer>
    {menu => (
      <nav className="navbar">
        <Link to="/" onClick={menu.closeMenu} className="navbar__logo">
          <div className="navbar__logo__img"><img src={logo} alt="Logo" /></div>
          <p className="navbar__logo__text">Acolad</p>
        </Link>
        <button
          className={`hamburger hamburger--elastic ${ menu.open ? 'is-active' : '' }`}
          onClick={menu.toggleMenu}
          type="button"
          aria-label={`${ menu.open ? 'Refermer le menu' : 'Ouvrir le menu' }`}
          aria-expanded={menu.open}
          aria-controls="menu"
        >
          <span aria-hidden="true" className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
        <div id="menu" className={`navbar__menu ${ menu.open ? 'navbar__menu--open' : '' }`}>
          <ul>
            <li><Link to="/" onClick={menu.closeMenu} activeClassName="active">Accueil<span aria-hidden="true"></span></Link></li>
            <li><Link to="/services" onClick={menu.closeMenu} activeClassName="active">Services<span aria-hidden="true"></span></Link></li>
            <li><Link to="/projets" onClick={menu.closeMenu} activeClassName="active">Projets<span aria-hidden="true"></span></Link></li>
            <li><Link to="/equipe" onClick={menu.closeMenu} activeClassName="active">L'équipe<span aria-hidden="true"></span></Link></li>
            <li><Link to="/contact" onClick={menu.closeMenu} activeClassName="active">Contact<span aria-hidden="true"></span></Link></li>
          </ul>
        </div>
      </nav>
    )}
  </MenuContext.Consumer>
)

export default Navbar

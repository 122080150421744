import React from 'react'
import Container from '@components/shared/container'
import Layout from '@components/layout/layout'

const NotFoundPage = () => (
  <Layout>
    <Container>
      <div className="page-padding">
        <div className="page404">
          <h1>Erreur 404</h1>
          <p className="content">La page que vous avez demandée n'existe pas.</p>
        </div>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
